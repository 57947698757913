<template>
  <div class="fadeIn">
    <van-nav-bar
      title="安灯处理"
      left-text="返回"
      left-arrow
      @click-left="$router.back()"
    />
    <van-form>
      <van-field
        v-model="information.projectName"
        type="area"
        name="area"
        label="设备名称"
        readonly
      />
      <van-field
        readonly
        clickable
        name="picker"
        v-model="cause"
        label="选择原因"
        placeholder="点击选择原因"
        @click="showPicker = true"
      />
      <!-- :rules="[{ required: true, message: '请选择原因' }]" -->
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        v-model="area"
        type="area"
        name="area"
        label="备注"
        placeholder="请填写备注"
      />
      <van-field label="上传照片" placeholder="">
        <template #input>
          <span v-if="localData.length > 0">
            <van-image
              @click.stop="lookImage"
              v-for="(item, index) in localData"
              :key="index"
              width="100"
              height="100"
              :src="item"
            />
          </span>
          <van-image v-else width="100" height="100" alt="暂无图片" />
        </template>
        <template #button>
          <van-button
            size="small"
            plain
            hairline
            type="info"
            @click="scanClick"
            >{{ oneUrl ? "修改图片" : "上传图片" }}</van-button
          >
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button
          round
          block
          type="warning"
          native-type="submit"
          @click="updateAlarm"
          :disabled="flag"
          >关闭触发器</van-button
        >
        <van-button
          style="margin-top: 16px"
          round
          block
          type="info"
          native-type="submit"
          @click="submit"
          :disabled="flag"
          >{{ alarmDetails.disposeState == 2 ? "修改" : "提交" }}</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
let that;
import wx from "weixin-js-sdk";
import { Toast, Dialog, ImagePreview } from "vant";
import { ossUrl } from "../util/global";
import { dataURLtoBlob, blobToFile, ALARM_IMG } from "../util/constant.js";
// import { Notify } from "vant";
export default {
  data() {
    return {
      flagedit: true,
      alarmDetails: "",
      oneUrl: "",
      flag: false,
      projectId: "",
      information: {},
      alarmInfoId: "",
      openId: "",
      createId: "",
      area: "",
      cause: "",
      causeCode: "",
      columns: [],
      showPicker: false,
      localData: [],
      localIds: [],
      wechatObj: {},
      WARNING: ossUrl,
      ALARM: ALARM_IMG,
    };
  },
  methods: {
    async updateAlarm() {
      try {
        await this.$dialog.confirm({
          title: "标题",
          message: "是否禁用本条触发器？如需开启，请至设备中操作",
        });
        const res = await this.$api.warninglist.upDateStatus(this.alarmInfoId);
        if (res.code == 200) {
          this.$toast(res.msg);
        }
      } catch (error) {}
    },
    lookImage() {
      ImagePreview(this.localData);
    },
    getUrlKey: function (name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [",", ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getCode() {
      let _this = this;
      this.alarmInfoId = _this.getUrlKey("alarmInfoId"); //获取url参数code
      this.openId = _this.getUrlKey("openId"); //获取url参数code
      this.createId = _this.getUrlKey("createId"); //获取url参数code
    },
    // 获取接单状态
    async getOrderState() {
      let from = {
        alarmInfoId: this.alarmInfoId || "",
        openId: this.openId || "",
        createId: this.createId || "",
      };
      await this.$api.triColorLamp.getOrderState(from).then((res) => {
        if (res.code == 200) {
          this.alarmDetails = res.data;
          this.flag = false;
          this.projectId = res.data.projectId;
          this.area = res.data.operaContext || "";
          this.oneUrl = res.data.oneUrl || "";
          if (res.data.oneUrl && res.data.oneUrl.length) {
            let image = this.WARNING + this.ALARM + res.data.oneUrl;
            this.$set(this.localData, 0, image);
          }
          this.$set(this, "information", res.data);
          if (res.data.distributeState == 1) {
            //未结单
            this.isProcess(); //提示是否接单
          } else if (res.data.disposeState == 2) {
            Dialog.confirm({
              title: "安灯处理",
              message: "当前告警已前往处理，是否继续操作？",
              confirmButtonText: "继续编辑",
              cancelButtonText: "暂不处理",
            })
              .then(() => {
                this.getCauseList(); //获取原因列表
                that.getWechatInfo(); //获取相机授权
              })
              .catch(() => {
                this.getCauseList(); //获取原因列表
                this.flag = true;
              });
          } else {
            this.getCauseList(); //获取原因列表
            that.getWechatInfo(); //获取相机授权
          }
        } else {
          this.flag = true;
          Toast.fail(res.msg);
        }
      });
    },
    getCauseList() {
      this.columns = [];
      this.$api.setCause.getProjectCauseList(this.projectId).then((res) => {
        if (res.code == 200 && res.data) {
          for (let key in res.data) {
            let obj = {
              value: key * 1,
              text: res.data[key],
            };
            this.columns.push(obj);
          }
          this.columns.forEach((item) => {
            if (item.value == this.information.causeCode) {
              this.cause = item.text;
            }
          });
        }
      });
    },
    // 接单出现
    ProcessLoding() {
      Dialog.alert({
        message: "异常正在处理中，您无权操作",
      }).then(() => {
        // on close
      });
    },
    // 未接单出现
    isProcess() {
      Dialog.confirm({
        title: "安灯处理",
        message: "当前告警需要前往处理,是否处理？",
        confirmButtonText: "前往处理",
        cancelButtonText: "暂不处理",
      })
        .then(() => {
          let from = {
            openId: this.openId,
            alarmInfoId: this.alarmInfoId,
            createId: this.createId,
          };
          this.$api.triColorLamp.getinfomation(from).then((res) => {
            if (res.code == 200 && res.data) {
              this.getCauseList(); //获取原因列表
              that.getWechatInfo(); //获取相机授权
              let from = {
                alarmInfoId: this.alarmInfoId || "",
                openId: this.openId || "",
                createId: this.createId || "",
              };
              this.$api.triColorLamp.getOrderState(from).then((res) => {
                if (res.code == 200) {
                  this.$set(this.information, "id", res.data.id);
                }
              });
              Toast.success("接单成功！");
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    onConfirm(value) {
      this.cause = value.text;
      this.causeCode = value.value;
      this.showPicker = false;
    },
    async submit() {
      // if (!that.localData.length) {
      //   Toast.fail("请先上传图片再提交！");
      //   return;
      // }
      let base64 = that.localData[0]?.split(",")[0].indexOf("data:image") > -1;
      let from = {
        // oneUrl: this.oneUrl || "",
        createId: this.createId || "",
        alarmInfoId: this.alarmInfoId * 1,
        openId: this.openId || "",
        id: this.information.id || "",
        causeCode: this.causeCode,
        operaContext: this.area,
      };
      if (base64) {
        let blob = dataURLtoBlob(that.localData[0]);
        let file = blobToFile(blob, new Date() + ".png");
        let formData = new FormData();
        formData.append("file", file);
        formData.append("typeName", "alarm");
        await that.$api.pub.uploadImg(formData).then((res) => {
          if (res.code == 200) {
            from.oneUrl = res.data;
          }
        });
      }
      await that.$api.triColorLamp.btnOK(from).then((res) => {
        if (res.code == 200) {
          this.flagedit = false;
          Toast.success(res.msg);
          let from = {
            alarmInfoId: this.alarmInfoId || "",
            openId: this.openId || "",
            createId: this.createId || "",
          };
          this.$api.triColorLamp.getOrderState(from).then((res) => {
            this.alarmDetails = res.data;
            this.flag = false;
            this.projectId = res.data.projectId;
            this.area = res.data.operaContext || "";
            this.oneUrl = res.data.oneUrl || "";
            if (res.data.oneUrl && res.data.oneUrl.length) {
              let image = this.WARNING + this.ALARM + res.data.oneUrl;
              this.$set(this.localData, 0, image);
            }
            this.$set(this, "information", res.data);
          });
        }
      });
    },
    getWechatInfo: function () {
      that.$api.createProject
        .scan(window.location.href)
        .then((response) => {
          if (response.code == 200) {
            that.wechatObj = response.data;
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: that.wechatObj.appId, // 必填，公众号的唯一标识
              timestamp: that.wechatObj.timestamp, // 必填，生成签名的时间戳
              nonceStr: that.wechatObj.nonceStr, // 必填，生成签名的随机串
              signature: that.wechatObj.signature, // 必填，签名，见附录1
              jsApiList: ["chooseImage"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
          }
        })
        .catch((err) => {
          Toast.fail(JSON.stringify(err));
        });
    },
    scanClick: function (idx) {
      wx.ready(function () {
        setTimeout(() => {
          // if (that.localData.length > 0) {
          //   Toast.fail("最多上传1张图片！");
          //   return;
          // }
          wx.chooseImage({
            count: 1, // 默认9
            jsApiList: ["chooseImage"],
            sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
            sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
            success: function (res) {
              that.localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
              that.localIds.forEach((item) => {
                wx.getLocalImgData({
                  localId: item, // 图片的localID
                  success: function (res) {
                    const localData = res.localData;
                    let imageBase64 = "";
                    if (localData.indexOf("data:image") == 0) {
                      //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
                      imageBase64 = localData;
                    } else {
                      //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
                      //此时一个正常的base64图片路径就完美生成赋值到img的src中了
                      imageBase64 =
                        "data:image/jpeg;base64," +
                        localData.replace(/\n/g, "");
                    }
                    // localData是图片的base64数据，可以用img标签显示
                    that.localData = [imageBase64];
                  },
                });
              });
            },
            error: function (err) {
              // Toast.fail(JSON.stringify(err));
            },
          });
        }, 100);
      });
      wx.error(function (res) {
        Toast.fail(JSON.stringify(res));
      });
    },
  },
  created() {
    this.getCode();
    that = this;
    if (this.$route.query.alarmInfoId) {
      this.alarmInfoId = this.$route.query.alarmInfoId;
    }
    // 接单状态
  },
  mounted() {
    if (window.localStorage.getItem("isFirst") == "true") {
      window.localStorage.setItem("isFirst", "false");
      window.location.reload();
    }
    this.getOrderState();
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>
